import React, { useRef, useEffect } from 'react'

const Player = ({ src, className }) => {
  const videoRef = useRef(null)

  const attemptPlay = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch(error => {
        console.error('Error attempting to play video', error)
      })
  }

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
    attemptPlay()
  }, [])

  return (
    <video
      className={className}
      src={src}
      ref={videoRef}
      playsInline
      muted
      loop
      autoPlay
    />
  )
}

export default Player
