import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '~/utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem 2rem 1rem 2rem;
  @media (max-width: ${breakpoints.s}px) {
    justify-content: center;
  }
`

export const MenuLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  z-index: 21;
  margin: 0 1rem;
  &:last-of-type {
    margin-right: 0;
  }
`
export const MenuLinkExternal = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem;
  z-index: 21;
`

export const LogoLink = styled(MenuLink)`
  margin: 0;
  @media (max-width: ${breakpoints.s}px) {
    transform: translateX(2rem);
    margin-left: auto;
  }
`

export const CartLink = styled(MenuLink)``

export const NavItems = styled.div`
  margin-left: auto;
`

export const CartCounter = styled.span`
  border: 1px solid white;
  color: white;
  border-radius: 3px;
  padding: 0 4px;
  margin-top: -1px;
  font-size: 1rem;
  float: right;
  margin-left: 5px;
  z-index: 20;
`

export const LogoContainer = styled.div`
  width: 200px;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100px;
    margin: 0 auto;
  }
`
