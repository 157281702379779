import React, { useContext } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import Logo from '~/images/evwhere_logo_white.svg'
import {
  CartCounter,
  Container,
  LogoLink,
  CartLink,
  Wrapper,
  LogoContainer,
  MenuLink,
  MenuLinkExternal,
  NavItems,
} from './styles'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <Wrapper>
      <Container>
        <LogoLink to="/">
          <LogoContainer>
            <img src={Logo} alt="EVWhere Logo" />
          </LogoContainer>
        </LogoLink>
        <NavItems>
          <MenuLink to="/cart">
            {hasItems && <CartCounter>{quantity}</CartCounter>}
            Cart
          </MenuLink>
        </NavItems>
      </Container>
    </Wrapper>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
