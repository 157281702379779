import React, { useContext } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import StoreContext from '~/context/StoreContext'
import { Container, Vid, Image } from './styles'

const SiteBackground = () => {
  const {
    store: {
      background: { video, asset },
    },
  } = useContext(StoreContext)

  const child = (
    <CSSTransition
      key={video ? 'v' + asset : 'i' + asset}
      classNames="item"
      timeout={200}
    >
      {video ? <Vid src={asset} /> : <Image imageSrc={asset} />}
    </CSSTransition>
  )
  return (
    <Container>
      <TransitionGroup>{child}</TransitionGroup>
    </Container>
  )
}

export default SiteBackground
