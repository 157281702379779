import styled from '@emotion/styled'
import Player from '~/components/player'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  background: black;
`

export const Vid = styled(Player)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 200ms ease-in;

  &.item-enter {
    opacity: 0;
  }
  &.item-enter-active {
    opacity: 1;
  }
  &.item-exit {
    opacity: 1;
  }
  &.item-exit-active {
    opacity: 0;
  }
`

export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 500ms ease-in;
  background-image: url(${props => props.imageSrc});
  background-position: center;

  &.item-enter {
    opacity: 0;
  }
  &.item-enter-active {
    opacity: 1;
  }
  &.item-exit {
    opacity: 1;
  }
  &.item-exit-active {
    opacity: 0;
  }
`
