import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css, keyframes } from '@emotion/core'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

const fadeUp = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      .screen-reader-only {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }
      ::selection {
        color: black;
        background: rgb(206, 255, 0);
      }
      body {
        margin: 0;
        padding: 0;
        color: white;
        background: black;
      }

      h1 {
        font-size: 1.4rem;
        font-weight: bold;
      }
      h2 {
        font-size: 1.2rem;
        font-weight: bold;
      }
      h3 {
      }

      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        box-sizing: border-box;
        font-size: 14px;

        @media (max-width: ${breakpoints.s}px) {
          font-size: 12px;
        }
      }
      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }
    `}
  />
)

export const Img = styled(Image)``

export const Container = styled.div`
  margin: 0 auto;
`
export const Overlay = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  animation: ${fadeUp} 0.4s ease;
  animation-iteration-count: 1;
  z-index: 20;
  padding: 8rem 2rem 2rem;

  @media (max-width: ${breakpoints.s}px) {
    padding: 4rem 1rem 1rem;
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Button = styled.button`
  font-size: 1rem;
  box-sizing: border-box;
  background: ${props => (props.secondary ? 'none' : 'rgb(206, 255, 0)')};
  border: 0;
  border-radius: 0;
  color: black;
  padding: 0.4rem 0.5rem 0.36rem;
  margin: 0.5rem 0;
  cursor: pointer;
  text-transform: uppercase;

  min-width: ${props => (props.secondary ? 0 : 0.8)}rem;

  &:hover {
    color: white;

    background: black;
  }
`

export const Select = styled.select`
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0.5em 3.5em 0.4em 1em;
  margin: 0.4rem 0;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0;
  outline: none;
  line-height: 1.4rem;
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 13px) calc(1em), calc(100% - 8px) calc(1em),
    calc(100% - 2em) 0.3em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    outline: 0;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`

export const Input = styled.input`
  box-sizing: border-box;
  padding: 0.5em 1em 0.5em 1em;
  margin: 0.4rem 0;
  text-transform: uppercase;
  width: 100%;
  outline: none;
  border-radius: 0;
`
